<template>
  <div>
    <b-container fluid class="container-wrapper" style="margin-top: 10px">
      <b-row>
        <b-col cols="12" class="pageTitleRwd">
          <span class="pageTitle">{{ $t("fangDe.title") }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-breadcrumb
          :items="breadcrumbItems"
          class="bread-crumb"
          style="font-size: 15px"
        ></b-breadcrumb>
      </b-row>
      <b-row>
        <b-col class="datepicker-wrapper" cols="12">
          <span>{{ $t("fangDe.selectTime") }}:</span>
          <div class="date-wrapper">
            <datepicker
              placeholder="日期"
              format="yyyy-MM-dd"
              wrapper-class="wrapper-class"
              input-class="date-picker"
            ></datepicker>
            <span>{{ $t("fangDe.to") }}</span>
            <datepicker
              placeholder="日期"
              format="yyyy-MM-dd"
              wrapper-class="wrapper-class"
              input-class="date-picker"
            ></datepicker>
            <downloadButton />
          </div>
        </b-col>
      </b-row>
      <!-- body -->
      <b-row align-h="center" class="mt-5 align-items-center">
        <span class="text-center">{{ $t("fangDe.chartTitleHour") }}: </span>
        <datepicker
          input-class="date-picker"
          wrapper-class="wrapper-class"
          format="yyyy-MM-dd"
          :value="Date.now()"
        ></datepicker>
        <downloadButton class="downloadBtn-marginTop-for-phone" />
      </b-row>
      <highcharts :options="stockOptions"></highcharts>
      <b-row align-h="center" class="mt-5 align-items-center">
        <span class="text-center">{{ $t("fangDe.chartTitleDay") }}: </span>
        <datepicker
          :value="Date.now()"
          input-class="date-picker"
          wrapper-class="wrapper-class"
          format="yyyy-MM"
        ></datepicker>
        <downloadButton class="downloadBtn-marginTop-for-phone" />
      </b-row>
      <highcharts :options="stockOptions"></highcharts>
      <b-row align-h="center" class="mt-5 align-items-center">
        <span class="text-center">{{ $t("fangDe.chartTitleMon") }}: </span>
        <datepicker
          :value="Date.now()"
          wrapper-class="wrapper-class"
          input-class="date-picker"
          format="yyyy"
        ></datepicker>
        <downloadButton class="downloadBtn-marginTop-for-phone" />
      </b-row>
      <highcharts :options="stockOptions"></highcharts>
    </b-container>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import downloadButton from '../components/DownloadButton'
export default {
  name: 'FangDe',
  components: {
    Datepicker,
    downloadButton
  },
  data () {
    return {
      breadcrumbItems: [
        {
          text: '首頁',
          to: {
            name: 'realtime'
          }
        },
        {
          text: '發電與用電資訊',
          active: true
        }
      ],
      stockOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        // subtitle: {
        //   text: 'Source: WorldClimate.com'
        // },
        xAxis: {
          categories: [],
          crosshair: true
        },
        yAxis: {
          categories: [0, 200, 400, 600],
          title: {
            text: 'Rainfall (mm)'
          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          },
          marker: {
            fillColor: 'blue',
            lineColor: 'white',
            lineWidth: 1,
            radius: 4,
            enabled: false
          }
        },
        series: [
          {
            name: 'Tokyo',
            data: [
              49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4,
              194.1, 95.6, 54.4
            ],
            dataLabels: {
              enabled: true
            }
          },
          {
            name: 'New York',
            data: [
              83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5,
              106.6, 92.3
            ]
          },
          {
            name: 'London',
            data: [
              48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3,
              51.2
            ]
          },
          {
            name: 'Berlin',
            data: [
              42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8,
              51.1
            ]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss">
@import "../assets/scss/FangDe.scss";
</style>
